<template>
    <div class="has-account-modal-wrapper">
        <div class="has-account-modal">
            <div class="close">
                <img
                    src="@/assets/images/close-icon.svg"
                    alt="close"
                    @click="$emit('close')"
                />
            </div>

            <div class="modal-content">
                <div class="left-section">
                    <h1 class="modal-title">{{ $t('hasAccountModalTitle1') }}</h1>
                    <router-link
                        :to="`/registration/${id}/0/0`"
                        class="modal-btn"
                    >
                      Déposer ma candidature
                    </router-link>
                </div>
                <div class="right-section">
                    <h1 class="modal-title">{{ $t('hasAccountModalTitle2') }}</h1>
                    <login-part :scholarshipTypeId="id" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoginPart from '@/components/login-part'
export default {
    name: 'has-account',
    props: ['id'],
    components: {
        LoginPart
    },
}
</script>

<style lang="scss" scoped>
a:not(.md-button):hover {
    text-decoration: none;
}
.md-theme-default a:not(.md-button) {
    color: #FFFFFF!important;
}
.has-account-modal-wrapper{
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    .has-account-modal {
        background: #FFFFFF;
        padding: 30px 30px 50px 50px;
    }

    .close {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;

        img {
            cursor: pointer;
        }
    }

    .modal-content {
        display: flex;
    }

    .left-section {
        padding-right: 25px;
        border-right: 1px solid #b3b3b3;
    }

    .right-section {
        padding: 0 25px;
    }

    .modal-title {
        font-weight: 900;
        font-size: 36px;
        line-height: 52px;
        margin-bottom: 25px;
    }

    .modal-btn {
        width: 205px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0045FF;
        color: #FFFFFF;
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        border-radius: 6px;
        cursor: pointer;
    }
}
</style>