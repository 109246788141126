<template>
  <div class="edhec-wrap">
    <div class="edhec-header">
      <Breadcrumbs
          :items="items"
      />
      <h1>{{ $t('audenciaTitle') }}</h1>
    </div>
    <div class="edhec-text_wrap">
      <h3>{{ $t('audenciaText1') }}</h3>
      <p class="edhec-text">
        <span class="list-number">1</span>
        <span>{{ $t('audenciaText11') }}</span>
      </p>
      <p class="edhec-text">
        <span class="list-number">2</span>
        <span>{{ $t('audenciaText12') }}</span>
      </p>
      <p class="edhec-text">
        <span class="list-number">3</span>
        <span>{{ $t('audenciaText13') }}</span>
      </p>
      <h3>{{ $t('audenciaText2') }}</h3>
      <p class="edhec-text">
        <span class="list-number">1</span>
        <span>{{ $t('audenciaText21') }}</span>
      </p>
      <p class="edhec-text">
        <span class="list-number">2</span>
        <span>{{ $t('audenciaText22') }}</span>
      </p>
      <h3>{{ $t('audenciaText3') }}</h3>
      <p class="edhec-text">
        <span class="list-number">1</span>
        <span>{{ $t('audenciaText31') }}</span>
      </p>
      <p class="edhec-text">
        <span class="list-number">2</span>
        <span>{{ $t('audenciaText32') }}</span>
      </p>

      <h3>{{ $t('audenciaInfo1') }}</h3>
      <p class="edhec-text">
        <span class="list-number">1</span>
        <span>{{ $t('howToApplyAudencia') }}</span>
      </p>
      <p class="edhec-text">
        <span class="list-number">2</span>
        <span>{{ $t('audenciaInfo3') }}</span>
      </p>

      <h3>{{ $t('audenciaQuestions') }}</h3>

      <div class="edhec-text">
        <span v-html="$t('audenciaQuestion1')"></span>
      </div>
      <div class="edhec-text">
        <span v-html="$t('audenciaQuestion2')"></span>
      </div>

    </div>
    <has-account
        v-if="currentScholarshipTypeId"
        :id="currentScholarshipTypeId"
        @close="currentScholarshipTypeId = ''"
    />
  </div>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs';
import HasAccount from "@/components/has-account";

export default {
  components: {
    Breadcrumbs,
    HasAccount
  },

  data: () => ({
    currentScholarshipTypeId: '',
    items: [
      {
        text: 'home',
        href: '/'
      },
      {
        text: 'boursesEtudes',
        href: '#',
      },
      {
        text: 'scholarshipAudencia',
        href: '#',
      }
    ]
  }),

  methods: {
    openHasAccount () {
      this.currentScholarshipTypeId = 4
    }
  }
}
</script>

<style lang="scss" scoped>
.edhec-wrap {
  .edhec-header {
    h1 {
      max-width: 706px;
      font-weight: 900;
      font-size: 36px;
      line-height: 46px;
      margin-bottom: 40px;

      @media(max-width: 768px) {
        font-size: 24px;
      }
    }
  }
  .edhec-text_wrap {
    max-width: 706px;

    h3 {
      font-weight: bold;
      font-size: 22px;
      line-height: 34px;
      margin: 40px 0 15px;
    }

    .edhec-text {
      display: flex;
      margin-bottom: 4px;

      span{
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .list-number {
      margin-right: 15px;
    }

  }

  .edhec-info {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-top: 40px;

    p{
      margin-top: 35px;
    }
  }
}
</style>

<style>
#isMaster {
  color: #6e99ff;
  cursor: pointer;
  margin-left: 5px;
}
</style>