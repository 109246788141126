<template>
    <div class="login-part-wrapper">
        <form action="" @submit.prevent="save">
            <div class="input-wrapper">
                <img class="login-page_icon" src="@/assets/images/user.svg" alt="user">
                <input v-model="form.UserName" type="email" placeholder="Email" required/>
            </div>
            <div class="input-wrapper">
                <img class="login-page_icon" src="@/assets/images/password.svg" alt="password">
                <input v-model="form.Password" type="password" placeholder="Mot de passe" required/>
            </div>
            <div class="link-wrapper">
                <router-link to="/forgot-password" class="login-part_link">Mot de passe oublié</router-link>
            </div>
            <div
                class="btn-login_wrapper"
                :class="{'btn-login_blue': blueButton}"
            >
                <button type="submit" class="btn-login">Connexion</button>
            </div>
            <Messages :messages="messages"></Messages>
            <Errors :errors="loginError"></Errors>
        </form>
        <notify-existing-user
            v-if="showExistingModal"
            :application="true"
            @close="toApplicationPage"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {errors} from "@/mixins/errors"
import {messages} from "@/mixins/messages"
import {printArray} from "@/mixins/printArray"
import Errors from "@/components/Errors"
import Messages from "@/components/Messages"
import NotifyExistingUser from '@/components/notify-existing-user'
export default {
    name: 'login-part',
    props: {
        blueButton: {
            type: Boolean,
            default: false
        },
        registration: {
            type: Boolean,
            default: false
        },
        scholarshipTypeId: {
            type: Number,
            default: 0
        }
    },
    components: {
        Errors,
        Messages,
        NotifyExistingUser,
    },
    mixins: [
        printArray,
        errors,
        messages,
    ],
    data: () => ({
        form: {
            UserName: '',
            Password: '',
            RememberMe: false
        },
        showExistingModal: false
    }),
    computed: {
        ...mapGetters('auth', ['loginErrors', 'userData']),
        ...mapGetters('applications', ['currentScholarshipTypeId']),
        loginError() {
            return [this.loginErrors]
        }
    },
    mounted() {
        if(this.loginErrors) {
            this.setLoginError('')
        }
    },
    methods: {
        ...mapActions('auth', ['login']),
        ...mapActions('applications',['getAllScholarships']),
        ...mapMutations('auth', ['setLoginError']),
        async save() {
            const success = await this.login(this.form)
            if(success) {
                await this.getAllScholarships()
                if(this.currentScholarshipTypeId) {
                    this.showExistingModal = true
                } else if(this.registration) {
                    this.$emit('logined')
                } else {
                    this.$router.push(
                        {
                            name: 'student-profile',
                            params: {
                                id: this.scholarshipTypeId,
                                studentId: this.userData.id,
                                profileId: this.$route.params.profileId || 0,
                            },
                        })
                }
            }
        },
        toApplicationPage() {
            this.showExistingModal = false
            this.$router.push({name: 'application'})
        }
    }
}
</script>

<style lang="scss" scoped>
.login-part-wrapper {
    .input-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px 0;
        background: #FFFFFF;
        border: 1px solid #CDD0D9;
        border-radius: 6px;
        position: relative;

        img {
            position: absolute;
            top: 20px;
            left: 25px;
        }

        input {
            width: 100%;
            height: 60px;
            padding-left: 55px;
            outline: none;
            border: none;
            border-radius: 6px;
            font-size: 18px;
            line-height: 32px;
        }
    }
    .link-wrapper {
        margin: 25px 0;
    }
    .login-part_link {
        text-decoration: none;
        color: #0045FF;
        font-size: 18px;
        line-height: 32px;
    }
    .btn-login {
        background-color: #FFFFFF;
        padding: 13px 32px;
        border-radius: 6px;
        font-weight: bold;
        font-size: 20px;
        line-height: 34px;
        outline: none;
        border: 1px solid #CDD0D9;
        cursor: pointer;

        &_wrapper {
            display: flex;
        }
    }

    .btn-login_blue {
        justify-content: center;
        button {
            background-color: #0045FF;
            color: #FFFFFF;
        }
    }
}
</style>